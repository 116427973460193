.filter,.sort{
    display: flex;
    align-items: center;
    /* min-height:  */
}
.filter span,.sort span{
    margin: 0 0.5em;
    font-size: 1.1rem;
    font-weight: bold;
}
.select-box{
    margin: 0 0.5em;
    transform: scale(0.8);
}
.product-operations-container{
    display: flex;
    padding: 1em;
    justify-content: space-between;
}
.product{
    padding: 1em 0em;
    display: flex;
    flex-wrap: wrap;
    gap: 1em;
    justify-content: center;
}

#clear-filter{
    white-space: nowrap;
    color: teal;
    font-weight: bolder;
    cursor: pointer;
}