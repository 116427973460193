.admin-add-product{
    display: flex;
    align-items: center;
    justify-content: center;
}
.add-product-container{
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    width: 500px;
    padding: 1em;
}

.add-product-item{
    display: flex;
    flex-direction: column;
    gap: 1em;
    margin: 1em 0;
}
.add-product-item label{
    font-weight: bold;
    font-size: 1.1rem;
}
.add-product-item input{
    padding: 0.8em;
}