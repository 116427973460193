.navbar{
    display: flex;
    justify-content: space-between;
    align-items: center;
 }

.navbar li{
    margin: 0.5em 1.4em;
    list-style: none;
}
.navbar-left ,.navbar-right{
    display: flex;
    align-items: center;
    gap:1.5em;
}
.navbar-search{
    justify-content: center;
    display: flex;
    align-items: center;
    /* padding: 2px; */
}
.navbar-search input{
    border:none;
}


.navbar-search input:focus{
    outline: none;
}

.logo{
    font-size: 2rem;
    font-weight: bold;
}

@media screen and (max-width:770px) {
    .logo{
        width: 100%;
        background-color: wheat;
        order: -1;
        text-align: center;
    }
    .navbar{
        flex-wrap: wrap;
        justify-content: center;
    }
    
}
