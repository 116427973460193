.chart {
    margin: 20px;
    padding: 20px;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  }
  
  .chartTitle {
    margin-bottom: 20px;
  }
.admin-home-info{
    margin: 5em 0;
    gap: 1em;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}
  .dashboard_cards {
    max-width: 280px;
    height: 140px;
    font-size: 1.3rem;
    border-radius: 12px;
    position: relative;
    flex: 1;
    flex-basis: 300px;
}

.dashboard_cards svg {
    position: absolute;
    bottom: 0;
}

.dashboard_cards_txt {
    margin: 1em;
    color: white;
}

.dashboard_cards_txt h5 {
    color: rgb(192, 192, 192);
    font-weight: normal;
    margin: 1em 0;
}

.dashboard_cards_txt span {
    font-weight: bolder;
}

.admin-title{
    margin: 1.2em;
    color: rgb(80, 78, 78);
}