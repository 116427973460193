.productlist-img{
    display: flex;
    align-items: center;

}

.productlist-img img{
    width: 70px;
}

.action{
    display: flex;
    gap: 1em;
    align-items: center;
    cursor: pointer;
}

.productlist-title{
    margin: 1em;
}