.admin-sideBar{
    border-right:1px solid rgba(19, 17, 17, 0.37);
    max-width: 300px;
    flex: 1;    
    height: 100vh;

}

.admin-sideBar h1{
    color: royalblue;
    margin: 1em 0;
    text-align: center;
    font-size: 1.7em;
}

.admin-sideBar-link li{
    padding: 1em 0em 1em 1.9em;
    font-size: 1.3rem;
    font-weight: 500;
   
}

.admin-sideBar-link li a{
    display: flex;
    color: rgb(36, 35, 35);
    align-items: center;
    gap: 1em;
}
.admin-icon{
  font-size: 1.4em !important;
}



@media screen and (max-width:700px){
    .admin-nav-hide{
        display: none;
    }
    .admin-sideBar{
        max-width: 110px;
    }
    
    .admin-sideBar-link li{
        border-bottom: 1px solid grey;
        padding: 1em 0em 1em 1.9em;
        font-size: 1.3rem;
        font-weight: 500;
       
    }

    .admin-sideBar h1{
        margin: 2em 0;
        text-align: center;
        font-size: 1rem;
    }
    
}

.admin-active{
    border-right: 5px solid royalblue;
    background: #4169e16b;
}