.login-container{
    display: flex;
    background-color: rgb(255, 238, 238);
    height: 100vh;
    justify-content: center;
    align-items: center;
   
}
.login{
    z-index: 99999 !important;
    box-shadow: rgb(250, 242, 242) 0px 2px 8px 0px;   
     display: flex;
    flex-direction: column;
    width: 450px;
    background-color: white;
    max-width: 500px;
    padding: 1em;
    position: relative;
    border-radius: 5px;
}
.login >*{
    margin: 1.8em !important ;
}



#user-error{
    margin-top: 0 !important;
    color: rgb(206, 9, 9);
}




.lines{
    position: absolute;
    height: 100%;
    background-color: black;
    margin: auto;
    width: 100%;
}
  
.line{

    position: absolute;
    width: 1px;
    height: 100%;
    top: 0;
    left: 50%;
    background: rgba(255, 255, 255, 0.1);
    overflow: hidden;
}

  .line::after{

      content: '';
      display: block;
      position: absolute;
      height: 15vh;
      width: 100%;
      top: -50%;
      left: 0;
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #ffffff 75%, #ffffff 100%);
      animation: drop 7s 0s infinite;
      animation-fill-mode: forwards;
      animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
  }
  
  .line:nth-child(1){
      
      margin-left: -25%;
    }
    .line::after{
        
        animation-delay: 2s;
    }
    
    .line:nth-child(3){
        
        margin-left: 25%;
    }
    .line::after{
        
        animation-delay: 2.5s;
    }
    
    
@keyframes drop{
    
    0%{
        
        top: -50%;
    }
    100%{

        top: 110%;
    }
} 


